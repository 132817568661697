export default [
  {
    title: lang("home"),
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: lang("devices"),
    route: 'devices',
    icon: 'ServerIcon',
  },
  {
    title: lang("receipts"),
    route: 'orders',
    icon: 'CalendarIcon',
  },
  // {
  //   title: lang("sub-users"),
  //   route: 'users',
  //   icon: 'UsersIcon',
  // },
  {
    title: lang("sections"),
    route: 'sections',
    icon: 'TagIcon',
  },
  {
    title: lang("API & Docs"),
    route: 'docs',
    icon: 'BookIcon',
  },
]
